import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type eventTimelineType = { timeline: string; start_time: string | null; end_time: string | null };
type eventDetailsType = { venue: string; note: string; start_time: Date | null; color: string; font_family: string; font_size: string };

interface useEventTimelineType {
    eventTimelinePDF: Array<eventTimelineType> | null;
    saveEventTimeline: (value: Array<eventTimelineType>, eventDetails: eventDetailsType) => void;
    eventDetails: eventDetailsType;
}

export const useEventTimeline = create<useEventTimelineType>()(
    persist(
        (set) => ({
            eventTimelinePDF: null,
            saveEventTimeline: (value: Array<eventTimelineType>, eventDetails: eventDetailsType) =>
                set(() => ({ eventTimelinePDF: value, eventDetails })),
            eventDetails: {
                venue: "",
                note: "",
                start_time: null,
                color: "#cb3050",
                font_size: "40px",
                font_family: "galindo_regular",
            },
        }),
        { name: "event_timeline", storage: createJSONStorage(() => localStorage) },
    ),
);
