import { ChangeEvent, useState } from "react";
import { format } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import DashboardModal from "@/components/modals/DashboardModal";
import Input2 from "@/components/form/Input2";
import { useEvent } from "@/hooks/useEvent";
import Spinner from "@/components/Spinner";
import SelectDateInput from "@/components/form/SelectDateInput";
import { VendorType } from "@/types";
import VendorPicker from "@/components/modals/VendorPicker";
import PlanningStageDropdown from "@/components/PlanningStageDropdown";
import { editPlanningTimelineRequest } from "@/axios/put-request";
import "@/styles/add_budget_category.scss";

const formDetails = {
    task: { label: "Task", name: "task", placeholder: "Task name" },
    stage: { label: "Stage", placeholder: "Stage", name: "stage" },
    deadline: { label: "Deadline", placeholder: format(new Date(), "dd MM yy"), name: "deadline" },
    tag: { label: "Tag", placeholder: "Tag", name: "tag" },
    owner: { label: "Owner", placeholder: "Owner", name: "owner" },
    note: { label: "Note", placeholder: "Note", name: "note" },
};

type planStage = "not_done" | "ongoing" | "done";

interface Props {
    onClose: () => void;
    ceremonyName: string;
    eventName: string;
    selectedPlanTimeline: FormValuesType;
}

type FormValuesType = {
    id: string;
    task: string;
    stage: planStage;
    deadline: string | null;
    tag: string;
    owner: VendorType | null;
    note: string;
};

export default function EditPlanningTimelineModal({ selectedPlanTimeline, onClose }: Props) {
    const { selectedEventId, activeCeremonyId } = useEvent();
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const [formValues, setFormValues] = useState<FormValuesType>(selectedPlanTimeline);

    async function updateEventDayFlowTask() {
        try {
            if (!formValues.task) {
                return toast.error("Task name is required");
            }

            setLoading(true);

            const response = await editPlanningTimelineRequest(
                { ...formValues, ceremony_id: activeCeremonyId },
                { eventId: selectedEventId, planningTimelineId: selectedPlanTimeline.id },
            );
            setLoading(false);
            if (!response.data.status) {
                return toast.error(response.data.message || "Oops an error occured");
            }
            onClose();
            queryClient.invalidateQueries({ queryKey: [`ceremony_${activeCeremonyId}_${selectedEventId}`] });
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            toast.error("Oops, unable to add planning timeline");
            onClose();
        }
    }

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>) {
        setFormValues((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            };
        });
    }

    function updateStageHandler(planningStage: planStage) {
        setFormValues((prev) => {
            return {
                ...prev,
                stage: planningStage,
            };
        });
    }

    function onSelectHandler(value: Date | string, name: string) {
        setFormValues((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    }

    function onSelectVendor(vendorDetails: VendorType | null) {
        setFormValues((prev) => {
            return {
                ...prev,
                owner: vendorDetails,
            };
        });
    }

    return (
        <DashboardModal
            title="Edit planning timeline"
            className="add_checklist_modal add_event_day_task_modal add_planning_timeline_modal"
            onClose={onClose}>
            <div className="add_checklist_modal">
                <div className="checklist_group">
                    <div className="checklist_row">
                        <div className="element_group">
                            <Input2
                                containerClassName="task_element"
                                value={formValues.task}
                                input={formDetails.task}
                                onChange={updateInputHandler}
                            />
                            <PlanningStageDropdown stage={formValues.stage} updateStageHandler={updateStageHandler} />
                            <SelectDateInput
                                value={formValues.deadline as Date | null}
                                label={formDetails.deadline.label}
                                placeholder={formDetails.deadline.placeholder}
                                onClick={(date) => onSelectHandler(new Date(date).toISOString(), formDetails.deadline.name)}
                            />
                            <Input2
                                containerClassName="task_element small_element"
                                value={formValues.tag}
                                input={formDetails.tag}
                                onChange={updateInputHandler}
                            />
                            <div className="vendor_input">
                                <div className="vendor_input_wrapper">
                                    <label>Owner</label>
                                    <VendorPicker
                                        vendor={
                                            formValues.owner ? { id: formValues?.owner?.id, name: formValues?.owner?.name } : { id: "", name: "" }
                                        }
                                        onSelectEventVendor={(__vendorDetails) => onSelectVendor(__vendorDetails)}
                                        addNew={false}
                                    />
                                </div>
                            </div>
                            <Input2
                                containerClassName="task_element note"
                                value={formValues.note}
                                input={formDetails.note}
                                onChange={updateInputHandler}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal_footer mt-3">
                <div className="button_group">
                    <button type="button" onClick={onClose} className="cancel_btn">
                        Cancel
                    </button>
                    <button type="button" className="btn create_btn d-flex align-items-center" disabled={loading} onClick={updateEventDayFlowTask}>
                        Update {loading && <Spinner className="ml-2" size={20} />}
                    </button>
                </div>
            </div>
        </DashboardModal>
    );
}
