import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type brandingType = {
    color: string;
    applyBranding: boolean;
};

interface useFilterType {
    brandDetails: brandingType;
    selectedEventId: string;
    activeCeremonyId: string;
    setSelectedEventId: (value: string) => void;
    setActiveCeremonyId: (value: string) => void;
    setUpdateBrandDetails: (value: brandingType) => void;
}

export const useEvent = create<useFilterType>()(
    persist(
        (set) => ({
            brandDetails: {
                color: "#cb3050",
                applyBranding: false,
            },
            setUpdateBrandDetails: (value: brandingType) => set(() => ({ brandDetails: { ...value } })),
            selectedEventId: "",
            activeCeremonyId: "",
            setSelectedEventId: (value) => set(() => ({ selectedEventId: value })),
            setActiveCeremonyId: (value) => set(() => ({ activeCeremonyId: value })),
        }),
        { name: "selected_event", storage: createJSONStorage(() => localStorage) },
    ),
);
