import { Link } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { HexColorPicker } from "react-colorful";

import NewDashboardLayout from "@/layout/NewDashboardLayout";
import { getUserDetails } from "@/axios/get-request";
import ProfileRow from "@/components/ProfileRow";
import { updateUserDetails } from "@/axios/put-request";
import { uploadProfileImage } from "@/axios/post-request";
import { UserList } from "@/types";
import useAuth from "@/hooks/useAuth";
import "@/styles/settings.scss";
import { PaletteIcon } from "lucide-react";
import Switch from "react-switch";
import { useEvent } from "@/hooks/useEvent";

const menu = [
    { text: "Profile", link: "/dashboard/settings", active: true },
    { text: "Account", link: "/dashboard/settings/account" },
    { text: "Vendors", link: "/dashboard/vendors" },
];

type SocialMediaType = Array<{ type: "Instagram" | "Tiktok"; link: string }>;
const socialsInput = {
    instagram: { name: "Instagram", label: "Instagram", placeholder: "IG Username" },
    tiktok: { name: "Tiktok", label: "Tiktok", placeholder: "Tiktok Username" },
};
const formInput = {
    name: { name: "name", label: "Name", placeholder: "Enter your name" },
    phone: { name: "phone", label: "Phone number", placeholder: "Enter your phone number" },
    password: { name: "password", label: "Password", placeholder: "*******************", type: "password" },
};

type loadingType = "name" | "phone" | "password" | "Instagram" | "Tiktok";

export default function SettingsPage() {
    const [loading, setLoading] = useState<loadingType | null>(null);
    const [previewImage, setPreviewImage] = useState("");
    const {brandDetails, setUpdateBrandDetails} = useEvent();
    const [image, setImage] = useState<File | null>(null);
    const [socialMedia, setSocialMedia] = useState<SocialMediaType>([
        { type: "Instagram", link: "" },
        { type: "Tiktok", link: "" },
    ]);
    const { updateUserProfile, signUserOut } = useAuth();
    const { data, status, refetch } = useQuery({
        queryKey: ["get_user"],
        queryFn: () => {
            return getUserDetails();
        },
    });
    const [profile, setProfile] = useState({
        name: "",
        phone: "",
        password: "",
        instagram: "",
        tiktok: "",
    });

    useEffect(() => {
        if (status === "success" && (!profile.name || !profile.phone)) {
            const _userName = data?.result?.business_name ? data?.result?.business_name : data?.result?.name;
            const userName = _userName ? _userName : "";
            const profileImg = data?.result?.profile_photo ? data?.result?.profile_photo : data?.result?.business_image;

            setProfile({
                ...profile,
                name: userName,
                phone: data?.result?.phone_number as string,
            });
            setPreviewImage(profileImg ? profileImg : "");

            if (data?.result?.social_media && Array.isArray(data?.result?.social_media) && data?.result?.social_media.length > 0) {
                setSocialMedia(data?.result?.social_media);
            }
        }
    }, [status]);

    function changeProfilePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const url = URL.createObjectURL(file);
        setPreviewImage(url);
        setImage(file);
    }

    function onInputChangeEventHandler(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.name === "phone" && isNaN(Number(event.target.value))) return;
        setProfile((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            };
        });
    }
    function onSocialsChangeEventHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        const socialMediaData = [...socialMedia];
        socialMediaData[index] = {
            ...socialMediaData[index],
            link: event.target.value,
        };
        setSocialMedia(socialMediaData);
    }

    async function updateNameHandler(onProfileSaveHandler: () => void) {
        try {
            if (!profile.name) {
                return toast.error("Name is required");
            }
            if (profile.name.length < 2) {
                return toast.error("Please enter valid name");
            }
            if (!data?.result?.id) return;
            setLoading("name");
            const userDetails = data.result.user_type === UserList.celebrant ? { name: profile.name } : { business_name: profile.name };
            await updateUserDetails(data.result.id, userDetails);
            if (image) {
                const formData = new FormData();
                formData.append("file", image);
                await uploadProfileImage(formData, data.result.id);
            }
            setLoading(null);
            onProfileSaveHandler();
            return refetch();
        } catch (error) {
            setLoading(null);
            return toast.error("Oops an error occured");
        }
    }

    async function updatePhoneHandler(onProfileSaveHandler: () => void) {
        try {
            if (!profile.phone) {
                return toast.error("Phone is required");
            }
            if (profile.phone.length < 9 || profile.phone.length > 11) {
                return toast.error("Invalid phone number");
            }
            if (!data?.result?.id) return;
            setLoading("phone");
            const result = await updateUserDetails(data.result.id, { phone_number: profile.phone });
            setLoading(null);
            if (!result.data.status) {
                return toast.error("Oops an error occured, unable to update your phone number");
            }
            toast.success("Phone number updated");
            onProfileSaveHandler();
            return refetch();
        } catch (error) {
            setLoading(null);
            return toast.error("Oops an error occured, unable to update your phone number");
        }
    }

    async function updateSocialHandler(type: "Tiktok" | "Instagram", onProfileSaveHandler: () => void) {
        try {
            const selectedSocials = socialMedia.filter((item) => item.type === type);
            if (type === "Tiktok" && !selectedSocials[0].link) {
                return toast.error(`${type} is required`);
            }

            if (!data?.result?.id) return;
            setLoading(type);
            const result = await updateUserDetails(data.result.id, { social_media: socialMedia });
            setLoading(null);
            if (!result.data.status) {
                return toast.error(`Oops an error occured, unable to update your ${type} details`);
            }
            toast.success(`${type} details updated`);
            onProfileSaveHandler();
            return refetch();
        } catch (error) {
            setLoading(null);
            return toast.error("Oops an error occured, unable to update your phone number");
        }
    }

    async function updatePasswordHandler(onProfileSaveHandler: () => void) {
        try {
            if (!profile.password) {
                return toast.error("Phone is required");
            }
            if (profile.password.length < 6) {
                return toast.error("Password is too short");
            }
            if (!data?.result?.id) return;
            setLoading("password");
            await updateUserProfile(profile.password);
            setLoading(null);
            onProfileSaveHandler();
            signUserOut("/new");
            return toast.success("Password updated");
        } catch (error) {
            setLoading(null);
            return toast.error("Oops an error occured");
        }
    }

    function updateBrandColor(color: string) {
        setUpdateBrandDetails({
            ...brandDetails,
            color
        });
    }

    function updateApplyBrandDetails(applyBranding:boolean) {
        setUpdateBrandDetails({
            ...brandDetails,
            applyBranding,
        });
    }

    return (
        <NewDashboardLayout title="Settings" description="Manage your profile, workspace and more.">
            <section className="settings_page">
                <aside>
                    <ul>
                        {menu.map((item, index) => {
                            const className = item?.active ? "active" : "";
                            return (
                                <li key={index} className={className}>
                                    <Link to={item.link}>{item.text}</Link>
                                </li>
                            );
                        })}
                    </ul>
                </aside>
                <div className="d-flex flex-column w-full">
                    <div className="settings_view">
                        <h4>Profile</h4>
                        <ProfileRow
                            withPhoto
                            index={1}
                            loading={loading}
                            formInput={formInput.name}
                            value={profile.name}
                            placeholder={formInput.name.label}
                            previewImage={previewImage}
                            onChange={onInputChangeEventHandler}
                            changeProfilePhotoHandler={changeProfilePhotoHandler}
                            onSaveHandler={updateNameHandler}
                        />
                        <ProfileRow
                            index={2}
                            loading={loading}
                            formInput={formInput.phone}
                            value={profile.phone}
                            placeholder={formInput.phone.label}
                            onChange={onInputChangeEventHandler}
                            onSaveHandler={updatePhoneHandler}
                        />
                        <ProfileRow
                            index={3}
                            loading={loading}
                            formInput={formInput.password}
                            value={profile.password}
                            placeholder={formInput.password.label}
                            onChange={onInputChangeEventHandler}
                            onSaveHandler={updatePasswordHandler}
                        />
                    </div>
                    <div className="settings_view">
                        <h4>Socials</h4>
                        {socialMedia.map((item, index) => {
                            const formInput = item.type === "Instagram" ? socialsInput.instagram : socialsInput.tiktok;
                            return (
                                <ProfileRow
                                    key={index}
                                    index={index}
                                    loading={loading}
                                    formInput={formInput}
                                    value={item.link}
                                    placeholder={formInput.placeholder}
                                    onChange={(e) => onSocialsChangeEventHandler(e, index)}
                                    onSaveHandler={(saveData) => updateSocialHandler(item.type, saveData)}
                                />
                            );
                        })}
                    </div>
                    <div className="settings_view">
                        <h4>Branding settings</h4>
                        <div className="profile_row">
                            <div className="d-flex w-full align-items-center justify-content-between">
                                <div className="">
                                    <div className="d-flex mb-2 align-items-center">
                                        <PaletteIcon />
                                        <h4 className="ml-3 mb-0">Brand Colors</h4>
                                    </div>
                                    <p className="mb-0">Primary Color</p>
                                </div>
                                <div className="hex_color_picker_wrapper">
                                    <div style={{ backgroundColor: brandDetails.color, height: "50px", width: "50px", minWidth: "50px" }} />
                                    <HexColorPicker color={brandDetails.color} onChange={updateBrandColor} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="settings_view mb-5">
                        <h4>Export Settings</h4>
                        <div className="profile_row">
                            <div className="d-flex align-items-center w-full justify-content-between">
                                <div>
                                    <h4>Include branding in exports</h4>
                                    <p>Your logo and colors will be applied to PDFs and images</p>
                                </div>
                                <Switch onColor="#cb3050" onChange={updateApplyBrandDetails} checked={brandDetails.applyBranding} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </NewDashboardLayout>
    );
}
