import { ChangeEvent, FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import DashboardModal from "@/components/modals/DashboardModal";
import Input2 from "@/components/form/Input2";
import Spinner from "@/components/Spinner";
import Select from "../form/Select";
import { getListOfBanks, getUserDetails, resolveBankDetails } from "@/axios/get-request";
import { formatStringToNumber } from "@/helper";
import { addVendorRequest } from "@/axios/post-request";
import { useEvent } from "@/hooks/useEvent";

const formDetails = [
    { label: "Vendor", name: "name", placeholder: "--" },
    { label: "Service", placeholder: "--", name: "service" },
    { label: "Email", placeholder: "-", name: "email", type: "email" },
    { label: "Phone Number", placeholder: "000 000 000", name: "phone_number" },
];
const bankNameInput = { label: "Bank Name", placeholder: "--", name: "bank_name" };
const accountNumberInput = { label: "Account Number", placeholder: "--", name: "account_number" };

type formValues = {
	name: string;
	service: string;
	email: string;
	phone_number: number | null;
	bank_name: string;
	bank_code: null | string;
	account_number: null | number;
};

enum inputNames {
	phone_number = "phone_number",
	account_number = "account_number",
}

interface Props {
	onClose: () => void;
}

export default function AddVendorModal({ onClose }: Props) {
    const [loading, setLoading] = useState(false);
    const { selectedEventId } = useEvent();
    const { data: userData } = useQuery({
        queryKey: ["get_user"], // fetch user details for a specific event.
        queryFn: getUserDetails,
    });
    const country = userData?.result?.country ? userData?.result?.country.toLowerCase() : "";
    const { data, status } = useQuery({
        queryKey: [`get_list_of_bank_${country}`],
        queryFn: () => getListOfBanks(country),
        enabled: !!userData?.result?.id,
    });
    const queryClient = useQueryClient();
    const [formValues, setFormValues] = useState<formValues>({
        name: "",
        service: "",
        email: "",
        phone_number: null,
        bank_name: "",
        account_number: null,
        bank_code: null,
    });

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.name === inputNames.phone_number || event.target.name === inputNames.account_number) {
            if (event.target.value && isNaN(Number(event.target.value))) {
                return;
            }
            if (event.target.value.length > 11 && event.target.name === inputNames.phone_number) {
                return;
            }
            if (event.target.value.length > 10 && event.target.name === inputNames.account_number) {
                return;
            }

            setFormValues({
                ...formValues,
                phone_number: event.target.value ? formatStringToNumber(event.target.value) : null,
            });
        }
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    }

    function onSelectBankNameHandler(event: ChangeEvent<HTMLSelectElement>) {
        const bankCode = event.target.options[event.target.selectedIndex].dataset.bankcode as string;
        setFormValues({
            ...formValues,
            bank_code: bankCode,
            bank_name: event.target.value,
        });
    }

    async function createVendorRequest(event: FormEvent<HTMLFormElement>) {
        try {
            event.preventDefault();
            if (!formValues.name) {
                return toast.error("Vendor's name is required");
            }
            if (!formValues.service) {
                return toast.error("Vendor's service is required");
            }
            if (!formValues.phone_number) {
                return toast.error("Vendor's phone number is required");
            }
            if (!formValues.bank_name) {
                return toast.error("Vendor's bank name is required");
            }
            if (!formValues.account_number) {
                return toast.error("Vendor's account number is required");
            }
            setLoading(true);
            const verifyBankDetails = await resolveBankDetails(`${formValues.account_number}`, `${formValues.bank_code}`);
            if (!verifyBankDetails.status) {
                setLoading(false);
                return toast.error("Invalid account number");
            }
            const addVendorResponse = await addVendorRequest({
                ...formValues,
                event_id: [selectedEventId],
                phone_number: `${formValues.phone_number}`,
                account_number: `${formValues.account_number}`,
                bank_code: `${formValues.bank_code}`,
                id: uuidv4(),
            });
            setLoading(false);
            if (!addVendorResponse.data.status) {
                return toast.error(addVendorResponse.data.message);
            }
            toast.success(addVendorResponse.data.message);
            queryClient.invalidateQueries({ queryKey: ["all_event_vendors"] });
            return onClose();
        } catch (error) {
            setLoading(false);
            console.log("error", error);
            toast.error("Invalid account number");
        }
    }

    return (
        <DashboardModal title="Add Vendor" description="Add an event vendor" className="small_width add_vendor_modal" onClose={onClose}>
            <form className="add_vendor_form" onSubmit={createVendorRequest}>
                <div className="input_row">
                    {formDetails.map((input, index) => {
                        const inputName = input.name as "name" | "service" | "email" | "phone_number";
                        const __inputValue = formValues[inputName];
                        const inputValue = __inputValue ? __inputValue : "";
                        return <Input2 key={index} value={inputValue} input={input} onChange={updateInputHandler} />;
                    })}
                    <Select input={bankNameInput} className="custom" value={formValues.bank_name} onChange={onSelectBankNameHandler}>
                        {status === "loading" ? (
                            <option>Fetching banks...</option>
                        ) : (
                            data?.result &&
							Array.isArray(data?.result) &&
							data?.result?.length > 0 && (
                                <>
                                    <option>Select bank</option>
                                    {data?.result?.map((item, index) => (
                                        <option key={index} value={item.name} data-bankcode={item.code}>
                                            {item.name}
                                        </option>
                                    ))}
                                </>
                            )
                        )}
                    </Select>
                    <Input2 input={accountNumberInput} value={formValues.account_number ?? ""} onChange={updateInputHandler} />
                </div>
                <div className="modal_footer pb-2">
                    <div className="button_group">
                        <button type="button" onClick={onClose} className="cancel_btn">
							Cancel
                        </button>
                        <button type="submit" className="btn create_btn d-flex align-items-center">
							Create {loading && <Spinner className="ml-2" size={20} />}
                        </button>
                    </div>
                </div>
            </form>
        </DashboardModal>
    );
}
