import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import DashboardModal from "@/components/modals/DashboardModal";
import Spinner from "@/components/Spinner";
import ShieldIcon from "@/assets/icon/ShieldIcon";
import { deletePlannningTimelineRequest } from "@/axios/delete-request";
import { useEvent } from "@/hooks/useEvent";
import useActivityLog from "@/hooks/useActivityLog";

interface Props {
    onClose: () => void;
    planTimelineId: string;
    timelineName: string;
}

export default function DeletePlanningTimelineModal({ planTimelineId, timelineName, onClose }: Props) {
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const { activeCeremonyId, selectedEventId } = useEvent();
    const { deleteEventTaskAuditRequest } = useActivityLog();

    const planTimelineText =
        planTimelineId && planTimelineId.includes(",")
            ? `${planTimelineId.split(",").length} timelines`
            : timelineName
                ? `${timelineName}'s timeline`
                : "timeline";

    async function deleteEventTaskHandler() {
        try {
            if (!planTimelineId) {
                return toast.error("Planning timeline id is required");
            }
            setLoading(true);
            const deleteChecklistResponse = await deletePlannningTimelineRequest(planTimelineId, selectedEventId, activeCeremonyId);
            if (!deleteChecklistResponse.data.status) {
                setLoading(false);
                return toast.error("Oops unable to delete timeline");
            }
            setLoading(false);
            const __timelineName = timelineName ? timelineName : planTimelineText;
            await deleteEventTaskAuditRequest(__timelineName);
            queryClient.invalidateQueries({ queryKey: [`ceremony_${activeCeremonyId}_${selectedEventId}`] });
            return onClose();
        } catch (error) {
            setLoading(false);
            console.log("error", error);
        }
    }


    return (
        <DashboardModal title="Delete Timeline" onClose={onClose} className="small_width delete_modal_view delete_user_event_modal">
            <section className="delete_category_cost_item">
                <div className="icon_wrapper">
                    <ShieldIcon />
                </div>
                <h4>Are you sure you want to delete {planTimelineText}?</h4>

                <button onClick={deleteEventTaskHandler} disabled={loading}>
                    Delete
                    {loading && <Spinner className="ml-2" size={20} />}
                </button>
            </section>
        </DashboardModal>
    );
}
