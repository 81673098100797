import { useQuery } from "@tanstack/react-query";
import { Dropdown } from "react-bootstrap";
import { ChangeEvent, Suspense, useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon, FlagTriangleRightIcon, PencilIcon, PlusIcon, Trash2Icon } from "lucide-react";

import { useEvent } from "@/hooks/useEvent";
import { getEvent } from "@/axios/get-request";
import { EventdayFlowType, EventType, VendorType } from "@/types";
import FilterDropdown from "@/components/FilterDropdown";
import SelectDateInput from "@/components/form/SelectDateInput";
import AddPlanningTimelineModal from "@/components/modals/AddPlanningTimelineModal";
import EditPlanningTimelineModal from "@/components/modals/EditPlanningTimelineModal";
import { generateColorFromId } from "@/helper";
import VendorPicker from "@/components/modals/VendorPicker";
import PlanningStageDropdown from "@/components/PlanningStageDropdown";
import DeletePlanningTimelineModal from "@/components/modals/DeletePlanningTimelineModal";
import { editPlanningTimelineRequest } from "@/axios/put-request";
import DefaultView from "@/components/DefaultView";
import LoaderIcon from "@/assets/icon/LoaderIcon";
import "@/styles/checklist_new.scss";
import "@/styles/event_day_flow.scss";
import "@/styles/planning_timeline.scss";

enum ModalList {
	add_planning_timeline_modal = "add_planning_timeline_modal",
	edit_planning_timeline_modal = "edit_planning_timeline_modal",
	delete_planning_timeline_modal = "delete_planning_timeline_modal",
}

const timelineHeader = ["Item", "Stage", "Deadline", "Tag", "Owner", "Notes"];

const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

type planStage = "not_done" | "ongoing" | "done";

type EventDataType = {
	message: string;
	status: number;
	result: EventType | null;
};

type FormValuesType = {
	id: string;
	task: string;
	stage: planStage;
	deadline: string | null;
	tag: string;
	owner: VendorType | null;
	note: string;
};

type groupByMonthType = { [key: string]: Array<FormValuesType> };

let timer: number;

const timeoutValue = 1000;

export default function PlanningTimeline() {
    const [modal, setModal] = useState<string | null>(null);
    const { selectedEventId, activeCeremonyId, setActiveCeremonyId } = useEvent();
    const [counter, setCounter] = useState({
        start: 0,
        end: 6,
    });
    const { data, status, isRefetching } = useQuery({
        queryKey: [`ceremony_${activeCeremonyId}_${selectedEventId}`],
        queryFn: () => getEvent(selectedEventId),
    });
    const [formValues, setFormValues] = useState<Array<FormValuesType>>([]);
    const [groupedTask, setGroupedTask] = useState<groupByMonthType | null>(null);
    const [selectedPlanTimeline, setSelectedPlanTimeline] = useState<FormValuesType | null>(null);
    const [selectedPlanningTimelines, setSelectedPlanningTimelines] = useState<string[]>([]);
    const [multipleTimelineIDs, setMultipleTimelineIDs] = useState("");

    const eventData = data?.data;
    const ceremonies = status === "success" && eventData?.result?.ceremonies ? eventData?.result.ceremonies : [];
    const selectedCeremony = activeCeremonyId ? ceremonies.filter((item) => item.id === activeCeremonyId) : [];
    const ceremonyName = selectedCeremony ? selectedCeremony[0]?.name : "";
    const eventName = eventData?.result ? eventData?.result?.event_name : "";
    const ceremonyChecklists: EventdayFlowType = [];

    useEffect(() => {
        if (
            status === "success" &&
			activeCeremonyId === "all" &&
			eventData?.result?.ceremonies &&
			Array.isArray(eventData?.result?.ceremonies) &&
			eventData?.result?.ceremonies.length > 0
        ) {
            setActiveCeremonyId(eventData?.result?.ceremonies[0]?.id);
        }
        if (eventData && selectedCeremony && selectedCeremony[0]?.planning_timeline) {
            getInitialFormValues(eventData);
        }
    }, [status, selectedCeremony[0]?.planning_timeline?.length, selectedEventId, isRefetching]);

    useEffect(() => {
        const groupedformValues = groupByMonth(formValues);
        if (groupedformValues) {
            setGroupedTask(groupedformValues);
        }
    }, [formValues?.length, data, formValues]);

    function getInitialFormValues(eventData: EventDataType) {
        if (eventData) {
            const ceremonies = eventData?.result?.ceremonies;
            const selectedCeremony = activeCeremonyId ? ceremonies?.filter((item: { id: string }) => item.id === activeCeremonyId) : [];
            if (selectedCeremony && selectedCeremony[0]?.planning_timeline) {
                setFormValues(selectedCeremony[0]?.planning_timeline);
            }
        }
    }

    function handleKeyUp(index: number) {
        try {
            window.clearTimeout(timer);
            timer = window.setTimeout(
                async () =>
                    await editPlanningTimelineRequest(
                        { ...formValues[index], ceremony_id: activeCeremonyId },
                        { eventId: selectedEventId, planningTimelineId: formValues[index].id },
                    ),
                timeoutValue,
            );
        } catch (error) {
            console.error("error", error);
        }
    }

    function handleKeyPress() {
        window.clearTimeout(timer);
    }

    async function updateStageHandler(planningStage: planStage, index: number) {
        const tempData = [...formValues];
        const updatePlanningStageObj = {
            ...tempData[index],
            stage: planningStage,
        };
        tempData[index] = updatePlanningStageObj;
        setFormValues(tempData);
        await editPlanningTimelineRequest(
            {
                ...updatePlanningStageObj,
                ceremony_id: activeCeremonyId,
            },
            { eventId: selectedEventId, planningTimelineId: tempData[index].id },
        );
    }

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        const tempData = [...formValues];
        tempData[index] = {
            ...tempData[index],
            [event.target.name]: event.target.value,
        };
        setFormValues(tempData);
    }

    async function updateOwnerHandler(owner: VendorType | null, index: number) {
        const tempData = [...formValues];
        const updateOwner = {
            ...tempData[index],
            owner,
        };
        tempData[index] = updateOwner;
        setFormValues(tempData);
        await editPlanningTimelineRequest(
            {
                ...updateOwner,
                ceremony_id: activeCeremonyId,
            },
            { eventId: selectedEventId, planningTimelineId: tempData[index].id },
        );
    }

    function switchMonth() {
        setCounter((prev) => {
            if (prev.start === 0) {
                return {
                    ...prev,
                    start: prev.start + 6,
                    end: prev.end + 6,
                };
            } else if (prev.start === 6) {
                return {
                    ...prev,
                    start: prev.start - 6,
                    end: prev.end - 6,
                };
            }

            return prev;
        });
    }

    function onCloseModal() {
        setModal(null);
        if (selectedPlanTimeline) {
            setSelectedPlanTimeline(null);
        }
    }

    function showPlanningTimelineModal() {
        setModal(ModalList.add_planning_timeline_modal);
    }

    if (selectedCeremony[0]?.eventdayFlow) {
        selectedCeremony[0]?.eventdayFlow.forEach((item) => {
            if (item.ceremony.id === activeCeremonyId) {
                ceremonyChecklists.push(item);
            }
        });
    }

    function onEditPlanTimelineHandler(timeline: FormValuesType) {
        setSelectedPlanTimeline(timeline);
        setModal(ModalList.edit_planning_timeline_modal);
    }

    function onDeletePlanTimelineHandler(timeline: FormValuesType) {
        setSelectedPlanTimeline(timeline);
        setModal(ModalList.delete_planning_timeline_modal);
    }

    const groupByMonth = (data: Array<FormValuesType>): groupByMonthType => {
        return data.reduce((acc: { [key: string]: Array<FormValuesType> }, item) => {
            if (!item.deadline) return acc;

            const date = new Date(item.deadline);
            const month: string = date.toLocaleString("en-US", { month: "long" }); // Convert month to name (e.g., "January")

            if (!acc[month]) {
                acc[month] = [];
            }

            acc[month].push(item);
            return acc;
        }, {});
    };

    function selectAllHandler() {
        if (selectedPlanningTimelines.length === formValues.length) {
            return setSelectedPlanningTimelines([]);
        }
        const allIds = formValues.map((item) => item.id);
        setSelectedPlanningTimelines(allIds);
    }

    function selectPlanningTimelineIdHandler(planningTimelineId: string) {
        setSelectedPlanningTimelines((prev) => {
            const stateCheck = prev.filter((itemId) => itemId === planningTimelineId)[0];

            if (stateCheck) {
                const planningTimelineArray = prev.filter((itemId) => itemId !== planningTimelineId);
                return planningTimelineArray;
            } else {
                return [...prev, planningTimelineId];
            }
        });
    }

    const timelineText = selectedPlanningTimelines.length > 1 ? "timelines" : "timeline";

    const deleteTimelineIds = multipleTimelineIDs ? multipleTimelineIDs : selectedPlanTimeline ? selectedPlanTimeline?.id : "";

    function deleteMultipleTimelines() {
        let timelineIds = "";
        selectedPlanningTimelines.forEach((item, index) => {
            timelineIds += index !== selectedPlanningTimelines.length - 1 ? `${item},` : item;
        });
        setMultipleTimelineIDs(timelineIds);
        setModal(ModalList.delete_planning_timeline_modal);
    }

    async function onSelectHandler(value: Date, index: number) {
        const tempData = [...formValues];
        const updateTimelineDeadline = {
            ...tempData[index],
            deadline: new Date(value).toISOString(),
        };
        tempData[index] = updateTimelineDeadline;
        setFormValues(tempData);
        await editPlanningTimelineRequest(
            {
                ...updateTimelineDeadline,
                ceremony_id: activeCeremonyId,
            },
            { eventId: selectedEventId, planningTimelineId: tempData[index].id },
        );
    }

    const timelineName = selectedPlanTimeline ? selectedPlanTimeline.task : "";

    return (
        <>
            <Suspense>
                {modal === ModalList.add_planning_timeline_modal && (
                    <AddPlanningTimelineModal eventName={eventName} ceremonyName={ceremonyName} onClose={onCloseModal} />
                )}
                {modal === ModalList.edit_planning_timeline_modal && selectedPlanTimeline && (
                    <EditPlanningTimelineModal
                        eventName={eventName}
                        ceremonyName={ceremonyName}
                        selectedPlanTimeline={selectedPlanTimeline}
                        onClose={onCloseModal}
                    />
                )}
                {modal === ModalList.delete_planning_timeline_modal && deleteTimelineIds && (
                    <DeletePlanningTimelineModal planTimelineId={deleteTimelineIds} timelineName={timelineName} onClose={onCloseModal} />
                )}
            </Suspense>
            <section className="checklist_page planning_timeline event_day_flow">
                <div className="checklist_view mt-4">
                    {formValues.length > 0 ? (
                        <>
                            <div className="title_row">
                                <div>
                                    <FlagTriangleRightIcon />
                                    <h3>Planning Timeline</h3>
                                </div>
                                <div className="button_row">
                                    <FilterDropdown title={ceremonyName} disabled={!selectedEventId}>
                                        {ceremonies.map((ceremony) => (
                                            <Dropdown.Item key={ceremony.id} onClick={() => setActiveCeremonyId(ceremony.id)}>
                                                {ceremony.name}
                                            </Dropdown.Item>
                                        ))}
                                    </FilterDropdown>
                                </div>
                            </div>
                            <div className="event_day_view">
                                <div className="months_group">
                                    <button onClick={switchMonth}>
                                        <ChevronLeftIcon />
                                    </button>
                                    <div className="monthly_task_view">
                                        {Months.slice(counter.start, counter.end).map((month, index) => {
                                            const color = generateColorFromId(month);
                                            const style = { backgroundColor: color };
                                            const taskValue = groupedTask && groupedTask[month] ? groupedTask[month].length : 0;
                                            const task = taskValue ? taskValue : "00";

                                            const taskText = taskValue > 1 ? "Tasks" : "Task";

                                            return (
                                                <div className="month_view" key={index}>
                                                    <div className="handlebar" style={style} />
                                                    <h4>{month}</h4>
                                                    <h6>
                                                        {task} <sup>{taskText}</sup>
                                                    </h6>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <button onClick={switchMonth}>
                                        <ChevronRightIcon />
                                    </button>
                                </div>
                                <div className="this_month_list table_view">
                                    <div className="table_row">
                                        <div className="action_group">
                                            {selectedPlanningTimelines.length > 0 && (
                                                <button onClick={deleteMultipleTimelines}>
													Delete {selectedPlanningTimelines.length} {timelineText}{" "}
                                                </button>
                                            )}
                                        </div>
                                        <div className="action_group">
                                            <button onClick={showPlanningTimelineModal}>
												Add new <PlusIcon />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="planning_timeline_table_view">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <input
                                                            checked={selectedPlanningTimelines.length === formValues.length ? true : false}
                                                            type="checkbox"
                                                            onChange={selectAllHandler}
                                                        />
                                                    </td>
                                                    {timelineHeader.map((item, index) => (
                                                        <td key={index}>{item}</td>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formValues.map((item, index) => {
                                                    const vendorDetails = {
                                                        id: item.owner?.id ? item.owner?.id : "",
                                                        name: item.owner?.name ? item.owner?.name : "",
                                                    };
                                                    const arrayCheck = selectedPlanningTimelines.filter((itemId) => itemId === item.id)[0];
                                                    const isSelected = arrayCheck ? true : false;
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <input
                                                                    checked={isSelected}
                                                                    type="checkbox"
                                                                    onChange={() => selectPlanningTimelineIdHandler(item.id)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    placeholder="Item"
                                                                    name="task"
                                                                    onChange={(event) => updateInputHandler(event, index)}
                                                                    value={item.task}
                                                                    onBeforeInput={handleKeyPress}
                                                                    onKeyUp={() => handleKeyUp(index)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <PlanningStageDropdown
                                                                    showLabel={false}
                                                                    stage={item.stage}
                                                                    updateStageHandler={(stage) => updateStageHandler(stage, index)}
                                                                />
                                                            </td>
                                                            <td>
                                                                {/* <input
																name="deadline"
																value={item.deadline ? format(new Date(item.deadline), "dd MM yyyy") : "--"}
																placeholder="00 00 0000"
																readOnly
															/> */}
                                                                <SelectDateInput
                                                                    value={item.deadline ? new Date(item.deadline) : null}
                                                                    placeholder="dd-mm-yyyy"
                                                                    onClick={(date) => onSelectHandler(date, index)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    name="tag"
                                                                    value={item.tag}
                                                                    placeholder="Tag"
                                                                    onBeforeInput={handleKeyPress}
                                                                    onKeyUp={() => handleKeyUp(index)}
                                                                    onChange={(event) => updateInputHandler(event, index)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <VendorPicker
                                                                    addNew={false}
                                                                    vendor={vendorDetails}
                                                                    onSelectEventVendor={(owner) => updateOwnerHandler(owner, index)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    name="note"
                                                                    value={item.note}
                                                                    placeholder="--"
                                                                    onBeforeInput={handleKeyPress}
                                                                    onKeyUp={() => handleKeyUp(index)}
                                                                    onChange={(event) => updateInputHandler(event, index)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <div className="button_group">
                                                                    {item.id && (
                                                                        <button onClick={() => onEditPlanTimelineHandler(item)}>
                                                                            <PencilIcon />
                                                                        </button>
                                                                    )}
                                                                    <button onClick={() => onDeletePlanTimelineHandler(item)}>
                                                                        <Trash2Icon />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <DefaultView
                            icon={<LoaderIcon />}
                            title="No Planning Timeline yet"
                            description="Create your planning timeline and arrange them in the order you need"
                            buttonText="Add planning timeline"
                            onClickHandler={showPlanningTimelineModal}
                        />
                    )}
                </div>
            </section>
        </>
    );
}
