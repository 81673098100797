import React, { ChangeEvent, useEffect, useState } from "react";
import { PDFViewer, Link, Document, Image, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import { Minus } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { HexColorPicker } from "react-colorful";

import { useEvent } from "@/hooks/useEvent";
import { useEventTimeline } from "@/hooks/useEventTimeline";
import { getEvent } from "@/axios/get-request";
import useUser from "@/hooks/useUser";
import { EventdayFlowType } from "@/types";
import Input from "./form/Input";
import Button from "./Button";
import whatsappIcon from "@/assets/img/whatsapp_icon.png";
import tiktokIcon from "@/assets/img/tiktok_icon.png";
import instagramIcon from "@/assets/img/instagram_icon.png";
import twitterIcon from "@/assets/img/twitter_icon.png";
import mailIcon from "@/assets/img/mail_icon.png";
import logo from "@/assets/img/logo.png";
import SelectTimeInput from "./form/SelectTimeInput";
import Textarea from "./form/Textarea";
import Select from "./form/Select";
import Checkbox from "./form/Checkbox";

Font.register({
    family: "galindo_regular",
    src: require("../assets/fonts/Galindo-Regular.ttf"),
});

Font.register({
    family: "RockSalt",
    src: require("../assets/fonts/RockSalt-Regular.ttf"),
});

Font.register({
    family: "Monoton",
    src: require("../assets/fonts/Monoton-Regular.ttf"),
});

Font.register({
    family: "EmblemaOne",
    src: require("../assets/fonts/EmblemaOne-Regular.ttf"),
});

Font.register({
    family: "helvetica",
    src: require("../assets/fonts/HelveticaNeueBold.ttf"),
});

Font.register({
    family: "sf_pro",
    src: require("../assets/fonts/SFPRODISPLAYREGULAR.OTF"),
});

type eventTimelineType = { timeline: string; start_time: string | null; end_time: string | null };
type eventDetailsType = { venue: string; note: string; start_time: Date | null; color: string; font_family: string; font_size: string };

const headerFontSizes = ["32px", "40px", "48px", "52px", "72px"];
const headerFonts = [
    { name: "RockSalt", value: "RockSalt" },
    { name: "Monoton", value: "Monoton" },
    { name: "EmblemaOne", value: "EmblemaOne" },
    { name: "Helvetica", value: "helvetica" },
    { name: "SF Pro", value: "sf_pro" },
    { name: "Galindo Regular", value: "galindo_regular" },
];

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "#fff",
        width: "100%",
        height: "100%",
    },
    header: {
        textTransform: "uppercase",
        textAlign: "center",
        marginBottom: 0,
        paddingBottom: 0,
        lineHeight: "60px",
    },
    title: {
        marginTop: 20,
        marginBottom: 0,
        textAlign: "center",
    },
    ceremonyTitle: {
        textTransform: "uppercase",
    },
    text: {
        fontSize: "12px",
    },
    line: {
        width: "100%",
        height: 2,
        backgroundColor: "#cb3050",
        marginBottom: 1,
    },
    venue: {
        bottom: 80,
        width: "100%",
        position: "absolute",
        left: 0,
        marginBottom: 20,
        justifyContent: "flex-end",
        paddingHorizontal: 20,
    },
    venueText: {
        fontSize: 12,
    },
    note: {
        marginTop: 10,
        fontSize: 12,
    },
    eventStartTime: {
        textAlign: "center",
        marginVertical: 40,
        marginBottom: 80,
        fontSize: 14,
        fontWeight: "bold",
    },
    tag: {
        color: "#cb3050",
        fontSize: 14,
        marginTop: 20,
        marginBottom: 10,
        textDecoration: "underline",
    },
    task: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "16px",
    },
    line2: {
        width: "100%",
        height: 2,
        backgroundColor: "black",
        marginTop: 2,
        marginBottom: 15,
    },
    bold: {
        fontWeight: "bold",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    footerLine: {
        bottom: 0,
        height: 80,
        width: "100%",
        position: "absolute",
        left: 0,
    },
    link: {
        fontSize: 12,
        color: "#cb3050",
        flexDirection: "row",
        alignItems: "center",
    },
    textLinkWrapper: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "50%",
        margin: "auto",
    },
    icon: {
        height: 20,
        width: 20,
    },
    watermark: {
        position: "absolute",
        top: "50%",
        left: "30%",
        width: 300,
        height: 80,
        transform: "translate(-50%, -50%) rotate(-45deg)",
        opacity: 0.1,
    },
    icons: {
        margin: "auto",
        marginTop: 20,
        marginBottom: 20,
        width: "20%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    duration: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
});

interface Props {
    eventDayFlow: EventdayFlowType;
}

export default function EventOverviewPdf({ eventDayFlow }: Props) {
    const { selectedEventId, activeCeremonyId } = useEvent();
    const { data } = useQuery({
        queryKey: [`ceremony_${activeCeremonyId}_${selectedEventId}`],
        queryFn: () => getEvent(selectedEventId),
    });
    const { data: userData } = useUser();
    const [eventTimeline, setEventTimeline] = useState<Array<eventTimelineType>>([{ timeline: "", start_time: null, end_time: null }]);
    const { eventTimelinePDF, saveEventTimeline, eventDetails: eventDetailsData } = useEventTimeline();
    const [eventDetails, setEventDetails] = useState<eventDetailsType>({
        venue: "",
        note: "",
        start_time: null,
        color: "#cb3050",
        font_size: "40px",
        font_family: "galindo_regular",
    });

    const selectedCeremony = data?.data?.result?.ceremonies?.filter((item) => item.id === activeCeremonyId);
    const ceremony = selectedCeremony ? selectedCeremony[0]?.name : "";

    useEffect(() => {
        if (eventTimelinePDF) {
            setEventTimeline(eventTimelinePDF);
            setEventDetails(eventDetailsData);
        }
    }, []);

    function selectEventStartTimeHandler(time: Date) {
        setEventDetails({
            ...eventDetails,
            start_time: time,
        });
    }

    function updateColor(color: string) {
        setEventDetails({
            ...eventDetails,
            color,
        });
    }

    const vendorName = userData?.result?.business_name ?? "";

    function saveChangesHandler() {
        saveEventTimeline(eventTimeline, eventDetails);
    }

    function onChangeHandler(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        setEventDetails({
            ...eventDetails,
            [event.target.name]: event.target.value,
        });
    }

    function onSelectDateHandler(date: Date | null, index: number, name: string) {
        setEventTimeline((prev) => {
            prev[index] = {
                ...prev[index],
                [name]: date,
            };
            return prev;
        });
    }

    function onInputChangeEventHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        setEventTimeline((prev) => {
            return prev.map((item, itemIndex) => {
                if (itemIndex === index) {
                    prev[index] = {
                        ...item,
                        timeline: event.target.value,
                    };
                    return prev[index];
                } else {
                    return item;
                }
            });
        });
    }

    function addMoreTimelineHandler() {
        setEventTimeline((prev) => [...prev, { timeline: "", start_time: null, end_time: null }]);
    }

    function removeTimelineHandler(index: number) {
        setEventTimeline((prev) => {
            const tempState = [...prev];
            const array = tempState.filter((_, idx) => idx !== index);
            return array;
        });
    }

    return (
        <section className="event_overview_pdf w-screen h-screen mt-5">
            <h5>Preview event day flow</h5>
            <div className="event_day_flow_pdf">
                <div className="customize_view">
                    <h6 className="mb-3">Overview details</h6>
                    <div className="event_start_time">
                        <SelectTimeInput
                            className="mb-3"
                            value={eventDetails.start_time ? new Date(eventDetails.start_time) : null}
                            placeholder="Event start time"
                            onClick={selectEventStartTimeHandler}
                        />
                    </div>
                    <Input className="mb-3" value={eventDetails.venue} input={{ name: "venue", placeholder: "Venue" }} onChange={onChangeHandler} />
                    <Textarea className="mb-3" value={eventDetails.note} input={{ name: "note", placeholder: "Note" }} onChange={onChangeHandler} />
                    <h6 className="mb-3">Timeline</h6>
                    {eventTimeline.map((item, index) => (
                        <div key={index} className="event_timeline_group mb-4">
                            <Input
                                value={item.timeline}
                                onChange={(event) => onInputChangeEventHandler(event, index)}
                                input={{ name: "timeline", placeholder: "Timeline for the day*" }}
                            />
                            <div className="d-flex align-items-center justify-content-between date_group_wrapper">
                                <div className="d-flex align-items-center justify-between date_group">
                                    <SelectTimeInput
                                        onClick={(time) => onSelectDateHandler(time, index, "start_time")}
                                        value={item.start_time ? new Date(item.start_time) : null}
                                        placeholder="Start time"
                                    />
                                    <SelectTimeInput
                                        value={item.end_time ? new Date(item.end_time) : null}
                                        placeholder="End time"
                                        onClick={(time) => onSelectDateHandler(time, index, "end_time")}
                                    />
                                </div>
                                <button className="ml-2 add_btn" onClick={() => removeTimelineHandler(index)}>
                                    <Minus />
                                </button>
                            </div>
                        </div>
                    ))}
                    <div className="mt-5 d-flex button__group align-items-center justify-content-between">
                        <Button text="Add timeline" onClick={addMoreTimelineHandler} className="border rounded-md" />
                        <Button text="Save" onClick={saveChangesHandler} className="submit_btn bg_red text-white" />
                    </div>
                </div>
                <div>
                    <PDFViewer className="w-full h-svh" style={{ width: "690px", height: "700px" }}>
                        <Document style={{ width: "100%" }}>
                            <Page size="A4" style={styles.page}>
                                <View style={styles.section}>
                                    <Text
                                        style={[
                                            styles.header,
                                            { fontFamily: eventDetails.font_family, fontSize: eventDetails.font_size, color: eventDetails.color },
                                        ]}>
                                        {vendorName ? vendorName : data?.data.result?.event_name}
                                    </Text>
                                    <View style={styles.line} />
                                    <View style={styles.line2} />
                                    <Text style={styles.ceremonyTitle}>TIMELINE FOR THE {ceremony}</Text>
                                    <View>
                                        <Text style={styles.tag}>Event day flow</Text>
                                        {eventDayFlow.map((item, index) => (
                                            <View key={index} style={styles.task}>
                                                <Text style={styles.text}>{item.task}</Text>

                                                {item?.start_time && <Text style={styles.text}>{item?.start_time}</Text>}
                                            </View>
                                        ))}
                                        {eventTimelinePDF &&
                                            eventTimelinePDF?.map((item, index) => (
                                                <View key={index} style={styles.task}>
                                                    <Text style={styles.text}>{item.timeline}</Text>
                                                    <View style={styles.duration}>
                                                        <Text style={styles.text}>
                                                            {item?.start_time ? format(new Date(item?.start_time), "h:mm a") : ""}
                                                        </Text>
                                                        {item?.end_time && (
                                                            <Text style={styles.text}>
                                                                {" "}
                                                                - {item?.end_time ? format(new Date(item?.end_time), "h:mm a") : ""}
                                                            </Text>
                                                        )}
                                                    </View>
                                                </View>
                                            ))}
                                    </View>
                                </View>
                                <Image style={styles.watermark} src={logo} />
                                <View style={styles.venue}>
                                    {eventDetailsData.start_time && (
                                        <Text style={styles.eventStartTime}>
                                            EVENT STARTS {format(new Date(eventDetailsData?.start_time), "h:mm a")}{" "}
                                        </Text>
                                    )}
                                    <Text style={styles.venueText}>
                                        <Text style={styles.bold}>Venue: </Text>
                                        {"  "}
                                        {eventDetailsData.venue}
                                    </Text>
                                    {eventDetailsData.note && (
                                        <Text style={styles.note}>
                                            <Text style={styles.bold}>Note: </Text>
                                            {"  "}
                                            {eventDetailsData.note}
                                        </Text>
                                    )}
                                </View>
                                <View style={styles.footerLine}>
                                    <View style={styles.line} />
                                    <View style={styles.line2} />
                                    <View>
                                        <View style={styles.textLinkWrapper}>
                                            <Link style={styles.link} href="mailto:info@planaday.events">
                                                <Image style={styles.icon} src={mailIcon} />
                                                <Text>info@planaday.events</Text>
                                            </Link>
                                            <Link
                                                style={styles.link}
                                                href="https://web.whatsapp.com/send?autoload=1&amp;app_absent=0&amp;phone=2347016548180&amp;">
                                                <Image style={styles.icon} src={whatsappIcon} />
                                                <Text>07016548180</Text>
                                            </Link>
                                        </View>
                                        <View style={styles.icons}>
                                            <Link style={styles.link} href="https://www.instagram.com/planaday_weddings/">
                                                <Image style={styles.icon} src={instagramIcon} />
                                            </Link>
                                            <Link style={styles.link} href="https://www.tiktok.com/@planaday">
                                                <Image style={styles.icon} src={tiktokIcon} />
                                            </Link>
                                            <Link style={styles.link} href="https://x.com/planadaywedding">
                                                <Image style={styles.icon} src={twitterIcon} />
                                            </Link>
                                        </View>
                                    </View>
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer>
                </div>
                <div className="customize_view">
                    <h6 className="mb-3">Customise</h6>
                    <div className="mt-3">
                        <Select
                            value={eventDetails.font_family}
                            onChange={onChangeHandler}
                            input={{ name: "font_family", label: "Header font family" }}>
                            {headerFonts.map((item, index) => (
                                <option value={item.value} key={index}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className="mt-3">
                        <Select value={eventDetails.font_size} onChange={onChangeHandler} input={{ name: "font_size", label: "Header font size" }}>
                            {headerFontSizes.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className="mt-3">
                        <HexColorPicker color={eventDetails.color} onChange={updateColor} />
                    </div>
                    <div className="mt-3">
                        <Checkbox input={{ name: "upload_image", label: "Upload image as header" }} />
                    </div>
                </div>
            </div>
        </section>
    );
}
